import { createContext, useContext, useMemo } from 'react';
import { mergeDeepAll, DeepPartial } from './merge-deep';
export { DeepPartial };

export interface LegoColorPalette {
  color50: string;
  color100: string;
  color200: string;
  color300: string;
  color400: string;
  color500: string;
  color600: string;
  color700: string;
  color800: string;
  color900: string;
}

export interface LegoTheme {
  default: LegoColorPalette;
  neutral: LegoColorPalette;
  accent: LegoColorPalette;
  positive: LegoColorPalette;
  negative: LegoColorPalette;
  special: LegoColorPalette;
}

// Internal context to manage the global theme
const LegoThemeContext = createContext<LegoTheme>(null as any);

/**
 * Each app using any legos must wrap their app in this provider
 */
export const LegoThemeProvider = LegoThemeContext.Provider;

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface BulkLegoTheme {
  // Empty, to be augmented by each lego
}

/**
 * When creating your lego theme, add the augment the BulkLegoTheme interface in your .theme.ts file:
 *
 * For example, if your lego is named `button` and your theme is `ButtonTheme`,
 * augment the type system like this
 *
 * ```ts
 * declare module 'legos/theme' {
 *   interface BulkLegoTheme {
 *     button: ButtonTheme;
 *   }
 * }
 * ```
 */

// Internal context to manage the overriding of lego components.
const BulkLegoThemeContext = createContext<DeepPartial<BulkLegoTheme>>({});

/*
 * Use this provider to override lego component themes based on the lego name.
 * Note!! this is not type safe, this is typically used with
 */
export const BulkLegoThemeProvider = BulkLegoThemeContext.Provider;

// Each lego impl should call this with its own mapper that can be used to
// transform the global theme into its own theme.
// The return is then a Provider that an app can use to override specific values for
// a lego, and a hook that the component can call to get its specified theme.
// Legos should re-export the ComponentThemeProvider using their own Component Name
// for apps to use
export const createUseThemeHook = <Theme extends object>(
  legoName: string,
  mapper: (global: LegoTheme) => Theme
) => {
  const ComponentContext = createContext<DeepPartial<Theme>>({});
  const useComponentTheme = () => {
    const globalTheme = useContext(LegoThemeContext);
    if (!globalTheme)
      throw new Error(
        'You must provide a `LegoTheme` to <LegoThemeProvider />'
      );
    const bulkLegoTheme = useContext(BulkLegoThemeContext);
    const componentTheme = useContext(ComponentContext);
    return useMemo<Theme>(
      (): Theme =>
        mergeDeepAll<Theme>(
          {},
          mapper(globalTheme),
          (legoName && bulkLegoTheme && (bulkLegoTheme as any)[legoName]) || {},
          componentTheme
        ),
      [globalTheme, bulkLegoTheme, componentTheme]
    );
  };

  return [ComponentContext.Provider, useComponentTheme] as const;
};
