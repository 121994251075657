export type DeepPartial<T extends object> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
};

export type MaybeDeepPartial<T> = T extends object ? DeepPartial<T> : T;

/**
 * Performs a deep merge of `source` into `target`.
 * Mutates `target` only but not its objects and arrays.
 *
 * @author inspired by [jhildenbiddle](https://stackoverflow.com/a/48218209).
 */
export const mergeDeep = <T>(
  target: MaybeDeepPartial<T>,
  source: MaybeDeepPartial<T>
): T => {
  const isObject = (value: unknown): value is object =>
    value && typeof value === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source as T;
  }

  for (const key of Object.keys(source) as Array<keyof T>) {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue) as any;
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(
        Object.assign({}, targetValue as any),
        sourceValue as any
      );
    } else {
      target[key] = sourceValue;
    }
  }

  return target as T;
};

export const mergeDeepAll = <T extends object>(
  target: DeepPartial<T>,
  ...sources: Array<DeepPartial<T>>
): T => sources.reduce<T>(mergeDeep as any, target as any);
